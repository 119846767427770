<template>
    <div>
      <b-modal id="modal-screenshot" size="lg" centered :title="tittle" v-model="showModal">
        <img class="model_backgroundimage" :src="modelImage" />
        <div style="overflow: auto" class="modal_feedback_data"></div>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    props: ['modelImage','tittle'],
  };
  </script>
  
  <style scoped>
  img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
  }
  .modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
  }
  </style>
  