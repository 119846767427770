<template>
    <b-form-group style="margin-top: 7px;" :label="tittle">
      <date-range-picker
        ref="picker"
        :opens="'left'"
        :locale-data="{
          direction: 'ltr',
          format: 'mm-dd-yyyy',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0,
        }"
        :timePicker="false"
        :timePicker24Hour="true"
        :showWeekNumbers="false"
        :showDropdowns="true"
        :autoApply="true"
        v-model="localDateRange"
        @update="handleDateRangeUpdate"
        :linkedCalendars="true">
        <template #ranges="ranges">
          <div class="ranges">
            <!-- {{ addAllInRanges(ranges.ranges) }} -->
            <ul>
              <!-- <li @click="selectAllDates()">
                <b>All</b>
              </li> -->
              <!-- <li v-for="(range, name) in addAllInRanges(ranges.ranges)" :key="name" @click="ranges.clickRange(range),selectAllDates()">
                <b>{{ name }}</b>
              </li> -->
              <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
                <b>{{ name }}</b>
              </li>
            </ul>
          </div>
        </template>
      </date-range-picker>
    </b-form-group>
  </template>
  
  <script>
  import {BFormGroup} from "bootstrap-vue";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  export default {
    components:{
        DateRangePicker,
        BFormGroup
    },
    props: {
    tittle: {
      type: String,
      default: 'Select Date Range',
    },
    dateRange: {
      type: Object,
      default: () => [{
        startDate: null,
        endDate: null,
      }],
    },
  },
    data() {
      return {
        localDateRange: { ...this.dateRange },
        localeData: {
          direction: 'ltr',
          format: 'mm-dd-yyyy',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          firstDay: 0,
        },
      };
    },
    watch: {
      // Watch for changes in prop and update localDateRange
      dateRange: {
        immediate: true,
        handler(newValue) {
          this.localDateRange = { ...newValue };
        },
      },
    },
    methods: {
      handleDateRangeUpdate(value) {
        console.log('value=',value)
        var startDate = value.startDate;
        var endDate = value.endDate;
        if(startDate && endDate){
          var month = startDate.getMonth() + 1;
          var day = startDate.getDate();
          var year = startDate.getFullYear();
          if (day < 10) {
            day = "0" + day;
          }
          if (month < 10) {
            month = "0" + month;
          }
          var formattedStartDate = year + "-" + month + "-" + day;
    
          month = endDate.getMonth() + 1;
          day = endDate.getDate();
          year = endDate.getFullYear();
          if (day < 10) {
            day = "0" + day;
          }
          if (month < 10) {
            month = "0" + month;
          }
          var formattedEndDate = year + "-" + month + "-" + day;
          // Emit the formatted date range to the parent component
          this.$emit('dateRangeSelected', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          });
        } else {
          const moment = require('moment');
          const startDate = moment('0000-01-01');
          const endDate = moment();
          const formattedStartDate = startDate.format('YYYY-MM-DD');
          const formattedEndDate = endDate.format('YYYY-MM-DD');
          // Emit the formatted date range to the parent component
          this.$emit('dateRangeSelected', {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          });
          this.dateRange = {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          };
        }
        this.localDateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
      },
      addAllInRanges(ranges) {
        const moment = require('moment');
        const startDate = moment('0000-01-01');
        const endDate = moment();
        const allRange = {
          "All": [
            startDate,
            endDate
          ]
        };
        ranges = { ...allRange, ...ranges }
        
        return ranges;
      },
      // selectAllDates() {
      //   const moment = require('moment');
      //   const startDate = moment('0000-01-01');
      //   const endDate = moment();
      //   // const formattedStartDate = startDate.format('YYYY-MM-DD');
      //   // const formattedEndDate = endDate.format('YYYY-MM-DD');
      //   const formattedStartDate = startDate.format('YYYY-MM-DD');
      //   const formattedEndDate = endDate.format('YYYY-MM-DD');
      //     this.dateRange = {
      //       startDate: formattedStartDate,
      //       endDate: formattedEndDate,
      //     };
      // },
      selectAllDates() {
        const moment = require('moment');
        const startDate = moment('0000-01-01');
        const endDate = moment();
        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        this.$emit('dateRangeSelected', {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
        this.dateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        this.$refs.picker.$emit("apply");
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your scoped styles here */
  </style>
  