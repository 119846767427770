<template>
  <b-sidebar
    id="feed-back-status-active"
    :visible="isFeedBackStatusActiveSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-feed-back-status-active-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Status</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

            <b-form-group label="Assign feedback" label-for="Assign-feedback">
              <v-select
                multiple
                :close-on-select="false"
                v-model="feedBackStatus.assginfeedBackIds"
                :options="allUser"
                label="name"
                input-id="add-name "
              ></v-select>
            </b-form-group>
            <b-form-group label="Status" label-for="Status">
              <b-form-select v-model="feedBackStatus.status" :options="status" />
            </b-form-group>
          <b-form-group label="Message" label-for="Status-Message">
            <b-form-textarea
              id="Status-Message"
              v-model="feedBackStatus.message"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </template>
    <template #footer="{ hide }">
      <div class="d-flex bg-light text-light align-items-center px-1 py-2">
        <!-- <div > -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          type="submit"
          @click="onSubmit"
        >
          Submit
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="ml-2"
          type="button"
          variant="outline-secondary"
          @click="hide"
        >
          Cancel
        </b-button>
      </div>
      <!-- </div> -->
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BCardText,
  BMedia,
  BAlert,
  BMediaAside,
  BMediaBody,
  BLink,
  BFormTextarea,
  BInputGroup,
  BImg,
  BFormFile,
  BFormSelect,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
export default {
  components: {
    BSidebar,
    BAlert,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormSelect,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isFeedBackStatusActiveSidebarActive",
    event: "update:is-feed-back-status-active-sidebar-active",
  },
  props: {
    isFeedBackStatusActiveSidebarActive: {
      type: Boolean,
      required: true,
    },
    editPropsStatusActive: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isFeedBackStatusActiveSidebarActive() {
      this.feedBackStatus.id = this.editPropsStatusActive.id;
      if (
        this.editPropsStatusActive.assginIds != "" &&
        this.editPropsStatusActive.assginIds != undefined
      ) {
        let finalArr = [];
        for (let i = 0; i < this.allUser.length; i++) {
          for (
            let index = 0;
            index < this.editPropsStatusActive.assginIds.length;
            index++
          ) {
            if (
              this.editPropsStatusActive.assginIds[index] ==
              this.allUser[i]["_id"]
            ) {
              finalArr.push(this.allUser[i]);
            }
          }
        }
        this.feedBackStatus.assginfeedBackIds = finalArr;
      } else {
        this.feedBackStatus.assginfeedBackIds = [];
      }
      this.feedBackStatus.status = this.editPropsStatusActive.status;
    },
  },
  data() {
    return {
      status: ["Pending", "Active", "Resolved", "Discard", "Dev Issue"],
      feedBackStatus: {
        id: "",
        message: "",
        status: "",
        assginfeedBackIds: [],
      },
      allUser: [],
    };
  },

  computed: {
  },
  created() {
    this.getUserData();
  },
  methods: {
    getUserData: function () {
      axios
        .post(process.env.VUE_APP_API_URL + "/feedback/details", {}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.allUser = get_response.data.users;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    onSubmit() {
      let finalArr = [];
      for (
        let index = 0;
        index < this.feedBackStatus.assginfeedBackIds.length;
        index++
      ) {
        finalArr.push(this.feedBackStatus.assginfeedBackIds[index]["_id"]);
      }
      this.feedBackStatus.assginfeedBackIds = finalArr;
      this.$emit("changeStatusFeedBack", this.feedBackStatus);
      this.$root.$emit("bv::toggle::collapse", "feed-back-status-active");
    },
    resetForm() {
      this.feedBackStatus = {
        id: "",
        message: "",
        status: "",
        assginfeedBackIds: [],
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
